@tailwind base;

/* MEMO: inspired by https://web.dev/prefers-color-scheme */
:root {
  color-scheme: light dark;
}
html {
  --duration: 0.5s;
  --timing: ease;

  /* MEMO: inspired by https://web.dev/prefers-color-scheme/#smooth-transitions-between-modes */
  transition: color var(--duration) var(--timing), background-color var(--duration) var(--timing);
}
/* MEMO: `light` and `dark` are hardcoded */
/* https://tailwindcss.com/docs/dark-mode#toggling-dark-mode-manually */
/* https://github.com/hipstersmoothie/storybook-dark-mode#darklight-class */
html.light {
  /* MEMO: inspired by https://web.dev/prefers-color-scheme/#invert-vector-graphics-and-icons */
  --icon-filter_hover: invert(60%);
}
html.dark {
  /* MEMO: inspired by https://web.dev/prefers-color-scheme/#re-colorize-and-darken-photographic-images */
  --image-filter: grayscale(50%);
  /* MEMO: inspired by https://web.dev/prefers-color-scheme/#invert-vector-graphics-and-icons */
  --icon-filter: invert(100%);
  --icon-filter_hover: invert(40%);
}
html.dark img:not([src*='.svg']) {
  filter: var(--image-filter);
}
html.dark img[src*='.svg'] {
  filter: var(--icon-filter);
}
img[src*='.svg']:hover {
  filter: var(--icon-filter_hover);
}

@tailwind components;
@tailwind utilities;

/* TODO: should scope it into components */
.fade-in-animation {
  animation: fade-keyframes 0.5s ease-in;
}
@keyframes fade-keyframes {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
