@import url('theme.base.css');

:root {
  --colors-light-blue: #075abc;
  --colors-light-blue-10: rgba(7, 90, 188, 0.1);
  --colors-light-blue-90: rgba(7, 90, 188, 0.9);
  --colors-lighter-blue: #c4e7fa;
  --box-shadow-black: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --color-light-grey: #eeeeee;
}

.theme-interlay {
  --colors-border: #dee3f5;
  --colors-bg-primary: var(--colors-neutral-white);
  /* Card */
  --color-card-primary-bg: var(--colors-neutral-white);
  --color-card-secondary-bg: var(--colors-light-blue-10);
  --color-card-tertiary-bg: #fbfbfc;
  /* CTA */
  --colors-cta-primary: var(--colors-light-blue);
  --colors-cta-primary-hover: var(--colors-light-blue-90);
  --colors-cta-primary-text: var(--colors-neutral-white);
  --colors-cta-secondary: var(--colors-lighter-blue);
  --colors-cta-secondary-text: var(--colors-light-blue);
  --colors-cta-outlined-text: var(--colors-neutral-black-9);
  --colors-cta-outlined-border: var(--colors-neutral-black-25);
  --colors-cta-outlined-hover: var(--colors-neutral-black-05);
  --colors-cta-text-text: var(--colors-neutral-black);
  --colors-cta-text-hover: var(--colors-neutral-black-05);
  /* Text */
  --colors-text-primary: var(--colors-neutral-black);
  --colors-text-secondary: var(--colors-light-blue);
  --colors-text-tertiary: var(--colors-neutral-light-grey);
  --box-shadow-default: var(--box-shadow-black);
  /* Tabs */
  --colors-tabs-bg: var(--colors-neutral-white);
  --colors-tabs-text: var(--colors-neutral-black-9);
  --colors-tabs-active-color: var(--colors-neutral-white);
  --colors-tabs-active-bg: var(--colors-light-blue);
  /* Loading Spinner */
  --colors-indeterminate-primary-color: var(--colors-light-blue);
  --colors-indeterminate-primary-bg: var(--colors-light-blue-10);
  --colors-indeterminate-secondary-color: var(--colors-lighter-blue);
  --colors-indeterminate-secondary-bg: var(--colors-neutral-black-20);
  --colors-indeterminate-outlined-color: var(--colors-neutral-black-80);
  --colors-indeterminate-outlined-bg: var(--colors-neutral-black-20);
  /* Meter */
  --colors-meter-bar-indicator-color: var(--colors-light-blue);
  --colors-meter-bar-separator-color: var(--colors-neutral-black);
  --colors-meter-bar-success-color: var(--colors-success-darker);
  --colors-meter-bar-warning-color: var(--colors-warning-dark);
  --colors-meter-bar-error-color: var(--colors-error-dark);
  /* Progress Circle */
  --color-progress-circle-mask: var(--colors-neutral-black-25);
  --color-progress-circle-fill: var(--colors-light-blue);
  /* Input */
  --colors-input-text: var(--colors-neutral-black);
  --colors-input-default-border: #dee3f5;
  --colors-input-hover-border: var(--colors-lighter-blue);
  --colors-input-focus-border: var(--colors-light-blue-90);
  --colors-input-disabled-text: #9a9a9a;
  --colors-input-disabled-border: var(--colors-neutral-lighter-grey);
  --colors-input-background: var(--colors-neutral-white);
  --colors-input-disabled-bg: #fbfbfc;
  /* Label */
  --colors-label-text: var(--colors-neutral-black);
  /* Token Input */
  --colors-token-input-end-adornment-bg: var(--colors-neutral-white);
  /* Token List */
  --colors-token-list-item-text: var(--colors-neutral-black);
  --colors-token-list-item-select-text: var(--colors-neutral-white);
  /* Modal */
  --colors-title-primary: var(--colors-neutral-black);
  --colors-title-secondary: var(--colors-light-blue);
  /* Switch */
  --colors-switch-unchecked-bg: var(--colors-neutral-lighter-grey);
  --colors-switch-checked-bg: var(--colors-light-blue);
  --colors-switch-indicator-bg: var(--colors-neutral-white);
  /* Table */
  --colors-table-odd-row-bg: var(--colors-neutral-white);
  --colors-table-even-row-bg: #fbfbfc;
  --colors-table-row-hover-bg: #f0f1f2;
  --color-table-header-row-bg: #f4f3f5;
  --colors-table-border: #f4f3f5;
  /* Tooltip */
  --colors-tooltip-bg: var(--colors-neutral-white);
  --colors-tooltip-tip-bg: var(--colors-neutral-white);
  /* List */
  --color-list-primary-bg: var(--colors-bg-primary);
  --color-list-primary-hover-bg: #f0f1f2;
  --color-list-secondary-bg: var(--color-light-grey);
  --color-list-secondary-hover-bg: #f0f1f2;
  --color-list-selected-text: var(--colors-neutral-white);
  /* Icon */
  --color-icon-fallback-stroke: var(--colors-neutral-white);
  --color-icon-fallback-color: #f4f3f5;
  /* Select */
  --color-select-text: var(--colors-neutral-black);
  /* Slider */
  --colors-slider-thumb-bg: var(--colors-neutral-white);
  --colors-slider-thumb-hover-bg: var(--color-light-grey);
  --colors-slider-track-bg: #dee3f5;
  --colors-slider-track-fill-bg: var(--colors-light-blue);
  /* Alert */
  --colors-alert-info-border: var(--colors-light-blue);
  --colors-alert-info-bg: #dee3f5;
}
