@import url('theme.base.css');

:root {
  --colors-blue: #041333;
  --colors-dark-blue: #091133;
  --colors-mid-blue: #434e66;
  --colors-yellow: #ffcb00;
  --colors-yellow-05: rgba(255, 203, 0, 0.05);
  --colors-dark-yellow: #e6b700;
  --box-shadow-none: none;
  --colors-slate-gray: #6a7994;
  --colors-bronze: #d57b33;
}

.theme-kintsugi {
  --colors-border: var(--colors-mid-blue);
  --colors-bg-primary: var(--colors-dark-blue);
  /* Card */
  --color-card-primary-bg: var(--colors-dark-blue);
  --color-card-secondary-bg: var(--colors-neutral-white-06);
  /* TODO: add */
  --color-card-tertiary-bg: var(--colors-neutral-white-06);
  /* CTA */
  --colors-cta-primary: var(--colors-yellow);
  --colors-cta-primary-hover: var(--colors-dark-yellow);
  --colors-cta-primary-text: var(--colors-neutral-black);
  --colors-cta-secondary: var(--colors-neutral-white);
  --colors-cta-secondary-text: var(--colors-neutral-black);
  --colors-cta-outlined-text: var(--colors-yellow);
  --colors-cta-outlined-border: var(--colors-yellow);
  --colors-cta-outlined-hover: var(--colors-yellow-05);
  --colors-cta-text-text: var(--colors-neutral-white);
  --colors-cta-text-hover: var(--colors-neutral-white-05);
  /* Text */
  --colors-text-primary: var(--colors-neutral-white);
  --colors-text-secondary: var(--colors-yellow);
  --colors-text-tertiary: var(--colors-neutral-light-grey);
  --box-shadow-default: var(--box-shadow-none);
  /* Tabs */
  --colors-tabs-bg: var(--colors-dark-blue);
  --colors-tabs-text: var(--colors-neutral-lighter-grey);
  --colors-tabs-active-color: var(--colors-blue);
  --colors-tabs-active-bg: var(--colors-neutral-white);
  /* Loading Spinner */
  --colors-indeterminate-primary-color: var(--colors-neutral-white);
  --colors-indeterminate-primary-bg: var(--colors-neutral-black-20);
  --colors-indeterminate-secondary-color: var(--colors-neutral-black-80);
  --colors-indeterminate-secondary-bg: var(--colors-neutral-lighter-grey);
  --colors-indeterminate-outlined-color: var(--colors-yellow);
  --colors-indeterminate-outlined-bg: var(--colors-mid-blue);
  /* Meter */
  --colors-meter-bar-indicator-color: var(--colors-yellow);
  --colors-meter-bar-separator-color: var(--colors-neutral-white);
  --colors-meter-bar-success-color: var(--colors-success-dark);
  --colors-meter-bar-warning-color: var(--colors-warning-dark);
  --colors-meter-bar-error-color: var(--colors-error-dark);
  /* Progress Circle */
  --color-progress-circle-mask: var(--colors-mid-blue);
  --color-progress-circle-fill: var(--colors-yellow);
  /* Input */
  --colors-input-text: var(--colors-neutral-white);
  --colors-input-default-border: var(--colors-neutral-white-25);
  --colors-input-hover-border: var(--colors-slate-gray);
  --colors-input-focus-border: var(--colors-neutral-white);
  --colors-input-disabled-text: var(--colors-slate-gray);
  --colors-input-disabled-border: var(--colors-mid-blue);
  --colors-input-background: var(--colors-blue);
  --colors-input-disabled-bg: var(--colors-blue);
  /* Label */
  --colors-label-text: var(--colors-neutral-white);
  /* Token Input */
  --colors-token-input-end-adornment-bg: var(--colors-neutral-white-25);
  /* Token List */
  --colors-token-list-item-text: var(--colors-neutral-white);
  --colors-token-list-item-select-text: var(--colors-neutral-black);
  /* Modal */
  --colors-title-primary: var(--colors-neutral-white);
  --colors-title-secondary: var(--colors-bronze);
  /* Switch */
  --colors-switch-unchecked-bg: var(--colors-dark-blue);
  --colors-switch-checked-bg: var(--colors-yellow);
  --colors-switch-indicator-bg: var(--colors-mid-blue);
  /* Table */
  --colors-table-odd-row-bg: var(--colors-blue);
  --colors-table-even-row-bg: #101e3d;
  --colors-table-row-hover-bg: #1c2c46;
  --color-table-header-row-bg: #080e28;
  --colors-table-border: #080e28;
  /* Tooltip */
  --colors-tooltip-bg: var(--colors-blue);
  --colors-tooltip-tip-bg: var(--colors-blue);
  /* List */
  --color-list-primary-bg: var(--colors-bg-primary);
  --color-list-primary-hover-bg: #1c2c46;
  --color-list-secondary-bg: #101e3d;
  --color-list-secondary-hover-bg: #1c2c46;
  --color-list-selected-text: var(--colors-neutral-black);
  /* Icon */
  --color-icon-fallback-stroke: #232323;
  --color-icon-fallback-color: #040816;
  /* Select */
  --color-select-text: var(--colors-neutral-white);
  /* Slider */
  --colors-slider-thumb-bg: var(--colors-blue);
  --colors-slider-thumb-hover-bg: var(--colors-dark-blue);
  --colors-slider-track-bg: var(--colors-mid-blue);
  --colors-slider-track-fill-bg: var(--colors-yellow);
  /* Alert */
  --colors-alert-info-border: var(--colors-yellow);
  --colors-alert-info-bg: var(--colors-dark-blue);
}
