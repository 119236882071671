@import url('reset.css');

@font-face {
  font-family: 'inter';
  src: url('../../assets/fonts/inter/Inter-Light.ttf') format('truetype');
  font-weight: 300; /* light */
  font-style: normal;
}
@font-face {
  font-family: 'inter';
  src: url('../../assets/fonts/inter/Inter-Regular.ttf') format('truetype');
  font-weight: 400; /* regular */
  font-style: normal;
}
@font-face {
  font-family: 'inter';
  src: url('../../assets/fonts/inter/Inter-Medium.ttf') format('truetype');
  font-weight: 500; /* medium */
  font-style: normal;
}
@font-face {
  font-family: 'inter';
  src: url('../../assets/fonts/inter/Inter-Bold.ttf') format('truetype');
  font-weight: 700; /* bold */
  font-style: normal;
}

* {
  box-sizing: border-box;
  font-family: 'inter';
}

:root {
  --fonts-primary: 'inter';

  --font-weights-light: 300;
  --font-weights-book: 400;
  --font-weights-medium: 500;
  --font-weights-semibold: 600;
  --font-weights-bold: 700;
  --font-weights-extrabold: 800;

  --colors-neutral-white: #ffffff;
  --colors-neutral-white-05: rgba(255, 255, 255, 0.05);
  --colors-neutral-white-06: rgba(255, 255, 255, 0.06);
  --colors-neutral-white-25: rgba(255, 255, 255, 0.25);
  --colors-neutral-lighter-grey: #dadada;
  --colors-neutral-light-grey: #999999;
  --colors-neutral-light-grey-50: #9999997f;
  --colors-neutral-black: #000000;
  --colors-neutral-black-05: rgba(0, 0, 0, 0.05);
  --colors-neutral-black-20: rgba(0, 0, 0, 0.2);
  --colors-neutral-black-25: rgba(0, 0, 0, 0.25);
  --colors-neutral-black-30: rgba(0, 0, 0, 0.3);
  --colors-neutral-black-60: rgba(0, 0, 0, 0.6);
  --colors-neutral-black-80: rgba(0, 0, 0, 0.8);
  --colors-neutral-black-9: rgba(0, 0, 0, 0.9);

  --colors-shared-red: #cb5458;

  --colors-error: #ef4444;
  --colors-error-dark: #ff0000;
  --colors-error-20: rgba(239, 68, 68, 0.2);
  --colors-warning: #ff9900;
  --colors-warning-dark: #ffb100;
  --colors-warning-light: #ffcb00;
  --colors-warning-light-20: rgba(254, 202, 47, 0.2);
  --colors-success: #a2e75e;
  --colors-success-dark: #61ff00;
  --colors-success-darker: #54ac1a;
  --colors-success-20: rgba(162, 231, 94, 0.2);
  --colors-info: #e1e7f0;
  --colors-info-dark: #075abc;

  --text-xs: 0.75rem;
  --text-s: 0.875rem;
  --text-base: 1rem;
  --text-lg: 1.125rem;
  --text-xl: 1.25rem;
  --text-2xl: 1.5rem;
  --text-3xl: 1.875rem;
  --text-4xl: 2.25rem;
  --text-5xl: 3rem;
  --text-6xl: 4rem;

  --line-height-s: 1.125rem;
  --line-height-base: 1.3125rem;
  --line-height-lg: 1.4375rem;
  --line-height-xl: 2.5rem;

  --spacing-0: 0px;
  --spacing-1: 0.25rem;
  --spacing-2: 0.5rem;
  --spacing-3: 0.75rem;
  --spacing-4: 1rem;
  --spacing-5: 1.25rem;
  --spacing-6: 1.5rem;
  --spacing-8: 2rem;
  --spacing-10: 2.5rem;
  --spacing-12: 3rem;
  --spacing-14: 3.5rem;
  --spacing-16: 4rem;
  --spacing-18: 4.5rem;
  --spacing-28: 7rem;

  --rounded-sm: 2px;
  --rounded-rg: 4px;
  --rounded-md: 6px;
  --rounded-lg: 8px;
  --rounded-xl: 12px;
  --rounded-full: 9999px;

  --transitions-default: all 250ms ease;
}
